import React from 'react';
import Chart from 'react-apexcharts';

import Explain from './Explain';
import PotentialAdditionalRevenue from './PotentialAdditionalRevenue';
import Recommendation from './Recommendation';





export default function Score(props) {
	const chartState = {
		options: {
			plotOptions: {
				pie: {
					donut: {
						size: '95%',

					}
				}
			},
			labels: ['Optimal', 'Under', 'None'],
			legend: {
				show: false,
			}
		},
	}

	const formatScore = (value) =>
		new Intl.NumberFormat('de-DE', {
			minimumFractionDigits: 0,  
			maximumFractionDigits: 0,      
		}).format(value);


	
	return (
		<div className="overview">

			<div className="chart">
				<Chart options={chartState.options} series={props.series} type="donut" />
				<div className="score">
					<span style={{ fontFamily: ['Playfair Display', 'serif'] }}>Praxis</span> Insights Score
						<div className="metric">{formatScore(props.score)}</div>
				</div>

			</div>

			<Explain />

			<PotentialAdditionalRevenue />

			<div className="vdiv"></div>

			<div className="actions">
				<h2>Recommended Actions / Opportunities Summary</h2>
				<hr />

				{/* https://coolors.co/ff928b-ffac81-fec3a6-efe9ae-cdeac0 */}
				<div className="recommendations">
					<Recommendation avatarClass="FF928B" amount="10129.23" count="34"
						label="Appointments that have below average revenue">
						<div>
							There are 34 Appointments in your filter range that under average.
						This could point to missing bookings.</div>
					</Recommendation>

					<Recommendation avatarClass="FFAC81" amount="7621.23" count="26"
						label="Appointments that have no Bookings at all">
						<div>
							There are 34 Appointments in your filter range that under average.
							This could point to missing bookings.
						</div>
					</Recommendation>

					<Recommendation avatarClass="FEC3A6" amount="5231" count="21"
						label="Appointments that are not billable due to timing">
						<div>
							There are 34 Appointments in your filter range that under average.
							This could point to missing bookings.
						</div>
					</Recommendation>

					<Recommendation avatarClass="EFE9AE" amount="1262" count="10"
						label="Potential Appointments for Pro-Active Patient Outreach">
						<div>
							There are 34 Appointments in your filter range that under average.
							This could point to missing bookings.
						</div>
					</Recommendation>
					{/* CDEAC0 */}
				</div>
			</div>
		</div>
	);
}