import React from 'react';

export default function PotentialAdditionalRevenue(props) {

	const numberFormat = (value) =>
		new Intl.NumberFormat('de-DE', {
			style: 'currency',
			currency: 'EUR',
			minimumFractionDigits: 0,  
			maximumFractionDigits: 0,      
		}).format(value);

	return (
		<div className="revenue">
			<h2>Est. Pot. Revenue</h2>
			<hr/>
			<span className="metric">{numberFormat(24243)}</span>
		</div>
	);
}