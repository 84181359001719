import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';


import './index.css';

import { StateProvider } from './lib/Store';
import AwsLib from './lib/AwsLib';

import App from './App';
import * as serviceWorker from './serviceWorker';

AwsLib();

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <StateProvider>
      <App />
    </StateProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
