import React from 'react';
import {
  BrowserRouter as Router,
  Switch, Route, Redirect
} from "react-router-dom";

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import Login from "./modules/login/Login";
import Signup from "./modules/signup/Signup";
import Overview from "./modules/overview/Overview";
import Actionlist from './modules/actionlist/Actionlist';
import Patients from './modules/patients/Patients';
import AppointmentsModule from './modules/appointments/AppointmentsModule';
import {Test as Andy} from './modules/test/Test';

import Container from "./components/Container";

import Debug from "./components/Debug";



// fonts
import './static/fonts/Heebo/Heebo-Black.ttf';
import './static/fonts/Heebo/Heebo-Bold.ttf';
import './static/fonts/Heebo/Heebo-ExtraBold.ttf';
import './static/fonts/Heebo/Heebo-Light.ttf';
import './static/fonts/Heebo/Heebo-Medium.ttf';
import './static/fonts/Heebo/Heebo-Regular.ttf';
import './static/fonts/Heebo/Heebo-Thin.ttf';

// styles
import './App.scss';

function App() {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Router>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <Route path="/signup">
            <Signup />
          </Route>
          <Route path="/*">
            <Switch>
              <Container>
                <Route path="/overview">
                  <Overview />
                </Route>
                <Route path="/patients">
                  <Patients />
                </Route>
                <Route path="/appointments">
                  <AppointmentsModule />
                </Route>
                <Route path="/actionlist">
                  <Actionlist />
                </Route>
                <Route path="/test">
                  <Andy/>
                  
                </Route>

              </Container>
            </Switch>
          </Route>
        </Switch>
      </Router>
      {/* <Debug /> */}
    </MuiPickersUtilsProvider>
  );
}

export default App;
