import React from 'react';
// import { Link } from "react-router-dom";
import {
	useHistory,
	// useLocation
} from "react-router-dom";

import { IoIosArrowForward } from "react-icons/io";

export default function Item(props) {
	let history = useHistory();

	const Icon = (props.icon? props.icon: IoIosArrowForward);


	function clickHandler() {
		history.push(props.to);
	}

	return (
		<div className="menuLink" onClick={clickHandler} to={props.to}>
			<div className="label">{props.children}</div>
			<div className="icon"><div className="iconHolder"><Icon/></div></div>
		</div>
	);
}