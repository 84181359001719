import React, { useState } from 'react';
import { Auth } from "aws-amplify";

import { useHistory } from "react-router-dom";

import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import { FaRocket } from "react-icons/fa";

import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';


import BgImg from '../../static/bg/trees-in-park-257360.jpg';

import './login.scss';

export default function Login() {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [errorStatus, setErrorStatus] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');

	const history = useHistory();

	async function login() {
		console.log('Login with : ', email, password);

		try {
			Auth.signIn(email, password)
				.then(res => {
					console.log("AUTH : ", res)
					history.push('/overview');
				})
				.catch((e) => {
					console.log("ERROR LOGIN IN", e);
					setErrorStatus(true);
					setErrorMsg(e.message);
					return e;
				})
		} catch (e) {
			alert(e.message);
		}
	}


	return (
		<div className="loginScreen">

			<div className="background" style={{
				backgroundImage: `url(${BgImg})`
			}}>

			</div>


			<div className="box">
				<Paper elevation={3}>

					<div className="container">
						<div className="section">
							<Avatar className="logo"><FaRocket /></Avatar>
						</div>
						<br />
						<div className="section name">
							<span style={{ fontFamily: ['Playfair Display', 'serif'] }}>Praxis</span>
							<span>Insights</span>
						</div>
						<br />
						<div className="section inputs">
							<form noValidate autoComplete="off">
								<TextField
									required
									error={errorStatus}
									label="Required Username or Email"
									variant="filled"
									value={email}
									onChange={(event => {
										setEmail(event.target.value)
									})}
									id="loginUsername"
									helperText={errorMsg}
								/>
								<br />
								<TextField
									label="Password"
									type="password"
									autoComplete="current-password"
									variant="filled"
									error={errorStatus}
									value={password}
									onChange={(event => {
										setPassword(event.target.value)
									})}
									helperText={errorMsg}
								/>
								<br />
								<div className="rememberForgot">
									<FormControlLabel
										control={<Checkbox />}
										label="Remember me"
									/>
									<Link color="inherit">Forgot Password?</Link>
								</div>

								<br />
								<hr />
								<div className="buttons">
									<Button variant="contained" color="primary" onClick={login}>Login</Button>
								</div>

							</form>

						</div>
					</div>


				</Paper>

			</div>

		</div>
	);
}