import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

export function Test() {

    const [number, setNumber] = useState(0);
    const [complicatedResult, setComplicatedResult] = useState("");

    function changeNumber(event)
    {
        setNumber(event.target.value);
    }

    function calcNumber() {
        setComplicatedResult( <>
            <br/>I am the sub function <br />
            {number < 10 ? 
                "Number is smaller than 10":
                "Number is bigger than 10"
            }
         </>   
         )
    }

    return (
        <div>
            Please enter a number here: <br />
            <TextField label="Number" onChange={changeNumber}/> <br />
            <Button onClick={calcNumber}>send</Button>
           
            {complicatedResult}
        </div>

    );
}