import React, { useContext, useState, useEffect } from 'react';
import { store } from '../../lib/Store';

// Imports I needed for Chips for Filter - ARU
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import DateRange from '@material-ui/icons/DateRange';
import Today from '@material-ui/icons/Today';

// Constant for Styling the Material UI Compponents - ARU
const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'left',
		flexWrap: 'wrap',
		'& > *': {
		  margin: theme.spacing(0.5),
		},
	  },
  }));

export default function PivotSummary(props) {
	const { state, dispatch } = useContext(store);
	const config = state.pivots.overview;
	const dateFormat = "MMMM DD";

	//Constants for handling the Chip Component of Material UI - ARU
	const handleDelete = () => {
		console.info('You clicked the delete icon.');
		};

	// Neccesarry classes for styling of Material UI Components - ARU
	const classes = useStyles();
	const chipAttributes = {size: "small", color:"primary", variant: "outlined", onClick: props.drawer(true)}

	let start = config.time.from.startOf('day');
	let end = config.time.to.endOf('day');

	const [employees, setEmployees] = useState({});
	useEffect(() => {
		state.api.employees.getMap().then(res => {
			// console.log("Yo? "+new Date().getTime());
			setEmployees(res)
		})
	}, []);

	return (
		<div>
			<div className={classes.root}>
			{start.isSame(end, 'day') ? <Chip label={start.format(dateFormat) } icon={<Today/>} {...chipAttributes} /> : <Chip label={`${start.format(dateFormat)} to ${end.format(dateFormat)}`} icon={<DateRange/>} {...chipAttributes} />}

			{config.employees.all || config.employees.ids.length == 0 ?
				("") : (
					<>
						{config.employees.ids.map(id => {
							return <Chip label={employees[id] ? `${employees[id].name.last},${employees[id].name.first}`: id} onDelete={handleDelete} {...chipAttributes} />
						})}
					</>
				)}
			</div>
			<br/>		
			{config.appointment.all ?
				<span>All appointment types are included.</span> :
				<span>Only some appointments are included.</span>
			}

			{config.patient.all ?
				<span>All patients types are included.</span> :
				<span>Only some patients are included.</span>
			}

			{config.insurance.all ?
				<span>All insurance types are included.</span> :
				<span>Only some insurance are included.</span>
			}
		</div>
	);

}