import React, { useState } from 'react';
import Logo from './Logo';
import Item from './Item';


import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';


import './Menu.scss';


import headshot from '../../static/mock/headshot.png';
import { TiCompass } from "react-icons/ti";
import { IoIosCalendar } from "react-icons/io";
import { AiOutlineContacts } from "react-icons/ai";
import { MdPlaylistAddCheck } from "react-icons/md";
import { GrLogout } from "react-icons/gr";
import { IoIosChatbubbles } from "react-icons/io";
import { FaFileImport } from "react-icons/fa";


export default function Menu() {
	const useStyles = makeStyles((theme) => ({
		large: {
			width: theme.spacing(7),
			height: theme.spacing(7),
			border: "1px solid #fff"
		},
	}));

	const classes = useStyles();

	const [menuExpanded, setMenuExpanded] = useState(true);

	function handleClick(ev) {
		console.log('Click', ev);
		setMenuExpanded(!menuExpanded);
	}

	return (
		<div className={(menuExpanded ? 'menu expanded' : 'menu collapsed')}>
			<Logo onClick={handleClick} />
			<div className="menuLinks">
				<Item to="/overview" icon={TiCompass}>Overview</Item>
				<Item to="/actionlist" icon={MdPlaylistAddCheck}>Action List</Item>

				<Item to="/patients" icon={AiOutlineContacts}>Patients</Item>
				<Item to="/appointments" icon={IoIosCalendar}>Appointments</Item>
				<Item to="/test" icon={IoIosChatbubbles}>Test</Item>
			</div>
			
			<div className="aboveFooterItems">
				<Item to="/todo2" icon={FaFileImport}>Import Data</Item>
				<Item to="/todo" icon={GrLogout}>Logout</Item>
			</div>

			<div className="personFooter">
				<div className="avatarArea">
					<Avatar alt="Remy Sharp" src={headshot} className={'avatarBadge '+classes.large} />
				</div>
				<div className="nameArea">
					<div className="title">INTERNAL MEDICINE</div>

				</div>
				<div className="actions">settings | profile</div>
			</div>
		</div>
	);
}