import React from 'react';
import { FaRocket } from "react-icons/fa";

export default function Logo(props) {


	return (
		<div className="logo" onClick={props.onClick}>
			<div className="circle">
				<FaRocket/>
			</div>

			<span style={{ fontFamily: ['Playfair Display', 'serif'] }}>Praxis</span> Insights
		</div>
	);
}