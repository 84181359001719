import React, { useState, useContext, useEffect } from 'react';
import { store } from '../../lib/Store';

import './overview.scss';

import Score from './score/Score';
import DataTable from './datatable/DataTable';
import DataPivot from '../../components/datapivot/DataPivot';
import PivotSummary from './PivotSummary';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

// Imports I needed for Styling Material UI Buttons for Filter - ARU
import { makeStyles } from '@material-ui/core/styles';
import Search from "@material-ui/icons/Search";
import Refresh from "@material-ui/icons/Refresh";


// Constant for Styling the Material UI Compponents - ARU
const useStyles = makeStyles((theme) => ({
	button: {
	  margin: theme.spacing(1),
	},
  }));



export default function Overview() {
	const { state, dispatch } = useContext(store);

	// Neccesarry classes for styling of Material UI Components - ARU
	const classes = useStyles();

	// chart
	const [series, setSeries] = useState([0, 0, 100]);
	const [score, setScore] = useState(0);

	const refreshData = () => {
		state.api.overview.get()
			.then(res => {
				console.log("The res: ", res);
				return res;
			})
			.then(res => {
				setSeries([res.score.optimal, res.score.under, res.score.none]);
				setScore(res.score.praxisInsightScore);
				return res;
			});
	}

	useEffect(refreshData, []);

	const [drawerState, setDrawerState] = React.useState({
		open: false,
	});
	  
	const toggleDrawer = (open) => (event) => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setDrawerState({ open: open });
	};

	return (
		<div className="overviewModule" >
			<h1>Revenue Score Overview</h1>

			<hr />
			<Button variant="contained"	color="primary"	className={classes.button} startIcon={<Search/>} onClick={toggleDrawer(true)}>Edit Filter</Button>
			<Button variant="contained" color="default" className={classes.button} startIcon={<Refresh/>} onClick={refreshData} >Refresh Data</Button>
			<PivotSummary drawer={toggleDrawer}/>
			<hr />

			<div className="time"></div>

			<Score series={series} score={score}/>
			<div className="data">
				<div className="title">
					<h1>Score Details</h1>
					<DataTable />
				</div>

			</div>
			<SwipeableDrawer anchor="right" open={drawerState.open}
				onClose={toggleDrawer(false)}
				onOpen={toggleDrawer(true)}>
				<div className="PivotDrawerContainer">

					<div className="overviewPivotDrawer">
						<DataPivot onChange={refreshData}/>
					</div>
					<AppBar position="static">
						<Toolbar>Data Settings for Analysis</Toolbar>
					</AppBar>
				</div>

			</SwipeableDrawer>
		</div>
	);
}