import React from 'react';

export default function Explain(props) {
	return (
		<div className="explain">
			The score is calculated by the net of booking optimized appointments minus under optimized appointments.
			Lost Revenue is estimated by averages.
				</div>

	);
}