import React from 'react';
import './actionlist.scss';

import { makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import * as moment from 'moment';
import * as faker from 'faker';


export default function Actionlist(props) {

  faker.seed(123);

  const numberFormat = (value) =>
		new Intl.NumberFormat('de-DE', {
			style: 'currency',
			currency: 'EUR',
			minimumFractionDigits: 0,  
			maximumFractionDigits: 0,      
		}).format(value);

  const columns = [
    {
      id: 'date', label: 'Date',
      minWidth: 170,
      format: (value) => value.format("MMMM DD,YYYY"),
    },
    {
      id: 'patient', label: 'Patient', minWidth: 100,
      format: (value) => <div>
        <span>{value.name.last}</span>,
        <span>{value.name.first}</span>
      </div>,
    },
    {
      id: 'doctor',
      label: 'Doctor',
      minWidth: 170,
      align: 'right',
      format: (value) => <div>
        <span>{value.name.last}</span>,
        <span>{value.name.first}</span>,
        <span>{value.name.title}</span>
      </div>,
    },
    {
      id: 'duration',
      label: 'Duration',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'revenue',
      label: 'Revenue',
      minWidth: 170,
      align: 'right',
      format: (value) => <div>
        <span>{numberFormat(value.actual)}</span> / 
        <span>{numberFormat(value.theoretical)}</span>
      </div>,
    },
    {
      id: 'bookings',
      label: 'Bookings',
      minWidth: 170,
      align: 'right',
      format: (value) => <div style={{
        textOverflow: 'wrap'
      }}>
        {value.map(booking=><div>{booking}</div>)}
      </div>,
    },
  ];

  function createData() {
    const date = moment(faker.date.past(30));
    const patient = {
      name: {
        first: faker.name.firstName(),
        last: faker.name.lastName(),
      }
    }
    const doctor = {
      name: {
        first: faker.name.firstName(),
        last: faker.name.lastName(),
        title: "MD"
      }
    }
    const duration = '1 hour';

    const revenue = {
      actual: faker.finance.amount(100, 500),
      theoretical: faker.finance.amount(200, 600)
    }

    const bookings = [];
    for(var i = 0; i < faker.random.number(15); i++) {
      bookings.push(faker.random.alphaNumeric(15));
    }

    return { date, patient, doctor, duration, revenue, bookings };
  }

  const rows = [];
  for(var i = 0; i < faker.random.number(300); i++) {
      rows.push(createData());
    }

  console.log("Rows : ", rows);

  const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    container: {
      height: '90%'
    }
  });

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <div className="actionlistModule">
      <div className="header"><h1>Recommended Actions</h1></div>
      <div className="config">
        <hr />
      </div>
      <div className="navigator">
        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar className="badge FF928B">37</Avatar>
            </ListItemAvatar>
            <ListItemText primary="Appointments that have below average revenue" secondary="Jan 9, 2014" />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar className="badge FFAC81">37</Avatar>
            </ListItemAvatar>
            <ListItemText primary="Appointments that have no Bookings at all" secondary="Jan 9, 2014" />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar className="badge FEC3A6">37</Avatar>
            </ListItemAvatar>
            <ListItemText primary="Appointments that are not billable due to timing" secondary="Jan 9, 2014" />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar className="badge EFE9AE">37</Avatar>
            </ListItemAvatar>
            <ListItemText primary="Potential Appointments for Pro-Active Patient Outreach" secondary="Jan 9, 2014" />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar className="badge CDEAC0">37</Avatar>
            </ListItemAvatar>
            <ListItemText primary="Appointments with no alerts and no warnings" secondary="Jan 9, 2014" />
          </ListItem>

        </List>

      </div>
      <div className="content">
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />

      </div>
    </div>
  );
}