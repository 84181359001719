import React, { createContext, useReducer } from 'react';
import { API } from "aws-amplify";
import update from 'immutability-helper';
import memoize from 'memoizee';
import * as moment from 'moment';


const convertArrayToObject = (initialValue, array, key) => {
	return array.reduce((obj, item) => {
		return {
			...obj,
			[item[key]]: item,
		};
	}, initialValue);
};

const apiGet = (url) => {
	console.log("ApiGet Called : ", url);
	return fetch(url)
		.then(res => res.json())
		.catch((e) => {
			console.log("ERROR GET URL : ", url, e);
			return e;
		})
}

const apiGetAsMap = (url) => {
	console.log("ApiGet Called : ", url);
	return fetch(url)
		.then(res => res.json())
		.then(res => convertArrayToObject({}, res, "id"))
		.catch((e) => {
			console.log("ERROR GET URL : ", url, e);
			return e;
		})
}



const apiPost = (url, obj) => {
	console.log("ApiPost Called : ", url);

	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(obj)
	};

	return fetch(url, requestOptions)
		.then(res => res.json())
		.catch((e) => {
			console.log("ERROR GET URL : ", url, e);
			return e;
		})

}



const cacheFn = (fn, ...args) => {
	let memoized = memoize(fn, {
		primitive: true,
		maxAge: 10000,
		preFetch: true,
	});
	return () => {
		// console.log("Memorized fn called");
		return memoized(...args);
	}

}

const initialState = {
	pivots: {
		overview: {
			time: {
				from: moment().subtract(1, 'day'),
				to: moment().subtract(1, 'day'),
			},
			employees: {
				all: true,
				ids: []
			},
			appointment: {
				all: true,
			},
			patient: {
				all: true,
			},
			insurance: {
				all: true,
			},
		}
	},
	api: {
		employees: {
			get: cacheFn(apiGet, "https://gbodol053k.execute-api.eu-central-1.amazonaws.com/dev/api/employees"),
			getMap: cacheFn(apiGetAsMap, "https://gbodol053k.execute-api.eu-central-1.amazonaws.com/dev/api/employees")
		},
		overview: {
			// get: () => apiPost("https://16tfzhpfyi.execute-api.eu-central-1.amazonaws.com/dev/api/score", initialState.pivots.overview)
			get: () => {
				console.log("API : ", API)
				return API.post("praxisfunctions", "/api/score", {body: initialState.pivots.overview})}
		}
	},
	util: {
		convertArrayToObject: convertArrayToObject
	}
};
const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
	const [state, dispatch] = useReducer((state, action) => {
		// const newState = update(state, action);
		//console.log("Global State : ", state);
		// return state;
		// console.log("hmm")
		return {
			pivots: state.pivots,
			api: state.api
		};
	}, initialState);
	return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider }