import React from 'react';
import './patients.scss';



export default function Patients() {


  return (
    <div className="patientModule">
      <div className="header"><h1>Browse Patients</h1></div>
      <div className="config">
        <hr />
      </div>
      <div className="navigator">


      </div>
      <div className="content">


      </div>
    </div>
  );
}