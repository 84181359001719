import React, { useEffect, useContext } from 'react';


// import {
// 	// useHistory,
// 	useLocation,
// } from "react-router-dom";



import Switch from '@material-ui/core/Switch';

import FormControlLabel from '@material-ui/core/FormControlLabel';


import './DataPivot.scss';

import Time from './Time';
import Employees from './Employees';
import AppointmentReasons from './AppointmentReasons';
import Patients from './Patients';
import Insurance from './Insurance';


export default function DataPivot(props) {

	// panels
	// const [expanded, setExpanded] = React.useState('employeePanel');
	const [expanded, setExpanded] = React.useState(false);
	const handleChange = (panel) => (event, isExpanded) => {
		// this is pretty gross but c'est la vie
		try {
			if (event.target.tagName === "INPUT" || event.target.className.includes('MuiFormControlLabel-label')) {
				return;
			}
		} catch (e) { }
		setExpanded(isExpanded ? panel : false);
	};


	return (
		<div className="datapivotComponent">

			<Time onChange={props.onChange}/>


			<div>
				<Employees onChange={props.onChange} panelName="employeePanel" expanded={expanded} handleChange={handleChange}/>
				<AppointmentReasons onChange={props.onChange} panelName="appointmentPanel" expanded={expanded} handleChange={handleChange}/>
				<Patients onChange={props.onChange} panelName="patientsPanel" expanded={expanded} handleChange={handleChange}/>
				<Insurance onChange={props.onChange} panelName="insurancePanel" expanded={expanded} handleChange={handleChange}/>
			</div>
		</div>
	);
}