import React from 'react';

// import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
// import { IoIosCalendar } from "react-icons/io";
import {
	useHistory,
	// useLocation
} from "react-router-dom";


export default function Recommendation(props) {
	let history = useHistory();

	const numberFormat = (value) =>
		new Intl.NumberFormat('de-DE', {
			style: 'currency',
			currency: 'EUR',
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
		}).format(value);

	function showRecommendation()
	{
		history.push("/actionlist");
	}

	return (

		<div className="Recommendation" onClick={showRecommendation}>

			<div className="icon">
				<Avatar className={`instanceCount ${props.avatarClass}`}>
					{props.count}
				</Avatar>
			</div>

			<div className="type">
				<h3>{props.label}</h3>
				<div className="desc">{props.children}</div>
			</div>
			<div className="opportunity">
				<div className="metric">{numberFormat(props.amount)}</div>
			</div>
		</div>
	);
}