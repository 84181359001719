import React, {useContext} from 'react';
import { store } from '../../lib/Store';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Switch from '@material-ui/core/Switch';
import { MdExpandMore } from "react-icons/md";
import FormControlLabel from '@material-ui/core/FormControlLabel';


export default function AppointmentReasons(props) {
	const { state, dispatch } = useContext(store);
	const config = state.pivots.overview.appointment;

	function masterSwitch() {
		config.all = !config.all;
		dispatch();
	}

	return (
		<div className="pivotSetting">
			<ExpansionPanel expanded={props.expanded === props.panelName} onChange={props.handleChange(props.panelName)}>
				<ExpansionPanelSummary
					expandIcon={<MdExpandMore />}
					aria-controls="panel1bh-content"
				>
					<FormControlLabel
						control={<Switch name="checkedA" />}
						label="Restrict analysis to certain Appointment Types"
						checked={!config.all}
						onChange={masterSwitch}
					/>

				</ExpansionPanelSummary>
				<ExpansionPanelDetails className="empSelector">
					<h1>Coming soon</h1>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		</div>
	);
}