import React, { useContext } from 'react';
import { store } from '../../lib/Store';


import Grid from '@material-ui/core/Grid';
import { KeyboardDatePicker } from '@material-ui/pickers';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';

import IconButton from '@material-ui/core/IconButton';
import { MdExpandMore } from "react-icons/md";

import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import clsx from 'clsx';
import * as moment from 'moment';


export default function Time(props) {
	const { state, dispatch } = useContext(store);
	const config = state.pivots.overview.time;

	const [expanded, setExpanded] = React.useState(false);


	const changeState = () => {
		props.onChange();
		dispatch();

	}

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	function changeFrom(d) {
		config.from = d.startOf('day')
		changeState();
	}

	function changeTo(d) {
		config.to = d.endOf('day')
		changeState();
	}

	function setTimeRange(from, to) {
		config.from = from.startOf('day')
		config.to = to.endOf('day')
		changeState();
	}

	function getYesterday() {
		return moment().subtract(1, 'day');
	}

	const internalDateFormat = "MMDDYYYY";

	function isYesterday() {
		return config.from.format(internalDateFormat) === getYesterday().format(internalDateFormat) &&
			config.to.format(internalDateFormat) === getYesterday().format(internalDateFormat);
	}

	function isWeek() {
		return config.from.format(internalDateFormat) === moment().startOf('isoWeek').format(internalDateFormat) &&
			config.to.format(internalDateFormat) === moment().endOf('isoWeek').format(internalDateFormat);
	}

	function isMonth() {
		return config.from.format(internalDateFormat) === moment().startOf('month').format(internalDateFormat) &&
			config.to.format(internalDateFormat) === moment().endOf('month').format(internalDateFormat);
	}

	function isQuarter() {
		return config.from.format(internalDateFormat) === moment().startOf('quarter').format(internalDateFormat) &&
			config.to.format(internalDateFormat) === moment().endOf('quarter').format(internalDateFormat);
	}

	function isYear() {
		return config.from.format(internalDateFormat) === moment().startOf('year').format(internalDateFormat) &&
			config.to.format(internalDateFormat) === moment().endOf('year').format(internalDateFormat);
	}

	return (
		<Card>
			<CardHeader title="Analysis Time Range"
				subheader={`${config.from.format("MMMM DD YYYY")} - ${config.to.format("MMMM DD YYYY")}`}
				action={
					<IconButton
						className={clsx('seeMore', {
							expanded: expanded
						})}
						onClick={handleExpandClick}
						aria-expanded={expanded}
						aria-label="show more">
						<MdExpandMore />
					</IconButton>
				}></CardHeader>
			<Collapse in={expanded} timeout="auto" unmountOnExit>
				<CardContent>
					<h5>Quick Date Ranges</h5>
					<Grid container
						direction="row"
						justify="flex-start"
						alignItems="flex-start"
						className="timeRangeShortcuts"
					>
						<Chip
							avatar={<Avatar>D</Avatar>}
							label="Yesterday"
							onClick={() => { setTimeRange(getYesterday(), getYesterday()) }}
							className={clsx({ active: isYesterday() })}
							variant="outlined"
						/>

						<Chip
							avatar={<Avatar>W</Avatar>}
							label="This Week"
							onClick={() => { setTimeRange(moment().startOf('isoWeek'), moment().endOf('isoWeek')) }}
							variant="outlined"
							className={clsx({ active: isWeek() })}
						/>

						<Chip
							avatar={<Avatar>M</Avatar>}
							label="This Month"
							onClick={() => { setTimeRange(moment().startOf('month'), moment().endOf('month')) }}
							variant="outlined"
							className={clsx({ active: isMonth() })}
						/>

						<Chip
							avatar={<Avatar>Q</Avatar>}
							label="This Quarter"
							onClick={() => { setTimeRange(moment().startOf('quarter'), moment().endOf('quarter')) }}
							variant="outlined"
							className={clsx({ active: isQuarter() })}
						/>

						<Chip
							avatar={<Avatar>Y</Avatar>}
							label="This Year"
							onClick={() => { setTimeRange(moment().startOf('year'), moment().endOf('year')) }}
							variant="outlined"
							className={clsx({ active: isYear() })}
						/>
					</Grid>


					<h5>Custom Date Ranges</h5>
					<Grid container justify="space-around" className="datePickers">

						<KeyboardDatePicker
							variant="inline"
							format="MM/DD/YYYY"
							margin="normal"
							label="From"
							value={config.from} onChange={changeFrom}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
						/>

						<KeyboardDatePicker
							variant="inline"
							format="MM/DD/YYYY"
							margin="normal"
							label="Through"
							value={config.to} onChange={changeTo}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
						/>
					</Grid>

				</CardContent>
			</Collapse>

		</Card>
	);

}