import { Amplify } from 'aws-amplify';

export default function AwsLib() {

	const config = {
		apiGateway: {
			REGION: "eu-central-1",
			URL: "https://gbodol053k.execute-api.eu-central-1.amazonaws.com/dev"
		},
		cognito: {
			REGION: "eu-central-1",
			USER_POOL_ID: "eu-central-1_YCeIGojnB",
			APP_CLIENT_ID: "42b73dl37k16qvjfgap4pbnsde",
			IDENTITY_POOL_ID: "eu-central-1:07001266-9bf7-49ef-8a2a-7cc41b34d8d3"
		}
	};

	Amplify.configure({
		Auth: {
			mandatorySignIn: true,
			region: config.cognito.REGION,
			userPoolId: config.cognito.USER_POOL_ID,
			identityPoolId: config.cognito.IDENTITY_POOL_ID,
			userPoolWebClientId: config.cognito.APP_CLIENT_ID
		},
		// Storage: {
		// 	region: config.s3.REGION,
		// 	bucket: config.s3.BUCKET,
		// 	identityPoolId: config.cognito.IDENTITY_POOL_ID
		// },
		API: {
			endpoints: [
				{
					name: "praxisfunctions",
					endpoint: config.apiGateway.URL,
					region: config.apiGateway.REGION
				},
			]
		}
	});

	console.log("AWS Configured");

}