import React, { useContext, useState, useEffect } from 'react';
import { store } from '../../lib/Store';

import clsx from 'clsx';
import update from 'immutability-helper';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Switch from '@material-ui/core/Switch';

import FormControlLabel from '@material-ui/core/FormControlLabel';

import { MdExpandMore } from "react-icons/md";
// import Typography from '@material-ui/core/Typography';

import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import Chip from '@material-ui/core/Chip';

export default function Employees(props) {
	const { state, dispatch } = useContext(store);
	const config = state.pivots.overview.employees;

	const [employees, setEmployees] = useState([]);

	useEffect(() => {
		console.log("Component Employees : useEffect")
		state.api.employees.get().then(res => setEmployees(res));
	}, [])

	const changeState = () => {
		props.onChange();
		dispatch();

	}

	function overwriteIds(selectedEmployees) {
		config.ids = Object.entries(selectedEmployees).filter(e => e[1] ? true : false).map(e => {
			return e[1].id;
		});
		changeState();
	}

	function handleFTEFilter(ev) {
		config.all = !config.all;
		changeState();

	}

	const [selectedEmployees, setSelectedEmployees] = useState({});
	function getSwitchHandler(fte) {
		return (ev) => {
			let lookup = "id_" + fte.id;
			let value = selectedEmployees[lookup];
			let spec = {};
			if (value) {
				spec[lookup] = { $set: undefined }
			} else {
				spec[lookup] = { $set: fte }
			}
			let newState = update(selectedEmployees, spec);
			setSelectedEmployees(newState);
			overwriteIds(newState);
		}
	}

	function isSelected(fte) {
		return selectedEmployees["id_" + fte.id] ? true : false;
	}

	return (
		<div className="pivotSetting">
			<ExpansionPanel expanded={props.expanded === props.panelName} onChange={props.handleChange(props.panelName)}>
				<ExpansionPanelSummary
					expandIcon={<MdExpandMore />}
					aria-controls="panel1bh-content"
				>
					<FormControlLabel
						control={<Switch name="checkedA" />}
						label="Restrict analysis to certain employee(s)"
						checked={!config.all}
						onChange={handleFTEFilter}
					/>

					<AvatarGroup max={3}>
						{
							Object.keys(selectedEmployees).map(key => {
								let value = selectedEmployees[key];
								if (value) {
									return <Avatar key={value.id} alt={`${value.name.last},${value.name.first}`} src="/static/images/avatar/1.jpg" />
								}
							})
						}
					</AvatarGroup>
				</ExpansionPanelSummary>

				{
					// console.log("WTF",employees.map(fte =>
					// 		<ListItem key={fte.id}>{fte.name} {fte.id}</ListItem>
					// 	))
				}


				<ExpansionPanelDetails className="empSelector">
					<div className="empList">
						<h4>Praxis Employees</h4>
						<List dense={true} className="list">
							{employees.map(fte =>
								<ListItem key={fte.id} className={clsx({ selected: isSelected(fte) })}>
									<FormControlLabel
										control={
											<Switch
												name="checkedB"
												color="primary"
												key={fte.id}
												onChange={getSwitchHandler(fte)}
											/>
										}
									/>
									<ListItemAvatar>
										<Avatar alt={`${fte.name.last},${fte.name.first}`} src="/static/images/avatar/1.jpg" >DK</Avatar>
									</ListItemAvatar>
									<ListItemText primary={`${fte.name.last},${fte.name.first}`} secondary={fte.name.title} ></ListItemText>
								</ListItem>

							)}
						</List>
					</div>
					{/* <div className="selectedListSection">
						<h4>Restrict Analysis to these employees</h4>
						<div className="selectedList">
							{
								Object.keys(selectedEmployees).map(key => {
									let value = selectedEmployees[key];
									if (value) {
										return <Chip
											className="chip"
											key={value.id}
											variant="outlined"
											size="small"
											avatar={<Avatar alt={value.name} src="/static/images/avatar/1.jpg" />}
											label={value.name}
										/>
									}
								})
							}
						</div>
					</div> */}


				</ExpansionPanelDetails>
			</ExpansionPanel>
		</div>
	);
}