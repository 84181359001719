import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
// import {
//   HelpBlock,
//   FormGroup,
//   FormControl,
//   ControlLabel
// } from "react-bootstrap";
import { useAppContext } from "../../lib/contextLib";

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import './signup.scss';



const Signup = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [confirmationCode, setConfirmationCode] = useState('');

	const history = useHistory();
	const [newUser, setNewUser] = useState(null);
	const { userHasAuthenticated } = useAppContext();


	async function handleSubmit(event) {
		event.preventDefault();
		try {
			const newUser = await Auth.signUp({
				username: email,
				password: password,
			});
			setNewUser(newUser);
		} catch (e) {
			console.log(e);
			alert(e.message);
		}
	}

	async function handleConfirmationSubmit(event) {
		event.preventDefault();
		try {
			await Auth.confirmSignUp(email, confirmationCode);
			await Auth.signIn(email, password);

			// userHasAuthenticated(true);
			history.push("/");
		} catch (e) {
			console.log(e);
			alert(e.message);
		}
	}



	function renderConfirmationForm() {
		return (
			<form onSubmit={handleConfirmationSubmit}>

				<TextField
					required
					label="Confirmation Code"
					variant="filled"
					value={confirmationCode}
					onChange={(event => {
						setConfirmationCode(event.target.value)
					})}
				/>

				<Button type="submit">Verify</Button>
			</form>
		);
	}

	function renderForm() {
		return (
			<form onSubmit={handleSubmit}>
				<TextField
					required
					label="Email"
					variant="filled"
					value={email}
					onChange={(event => {
						setEmail(event.target.value)
					})}
				/>
				<TextField
					required
					label="Password"
					type="password"
					variant="filled"
					value={password}
					onChange={(event => {
						setPassword(event.target.value)
					})}
				/>

				<Button type="submit">Signup</Button>
			</form>
		);
	}

	return (
		<div className="Signup">
			<Paper elevation={3}>
				{newUser === null ? renderForm() : renderConfirmationForm()}
			</Paper>
		</div>
	);
}

export default Signup;