import React from 'react';
import Menu from './menu/Menu';

import './Container.scss';



export default function Container(props) {
	return (
		<div className="container">
			<Menu/>
			<div className="contentpane">
				<div className="contentArea">{props.children}</div>
			</div>
		</div>

	);
}